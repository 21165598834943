// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './Menu.module.scss';

type Props = {
  menu: {
    label: string,
    path: string,
    type: string,
  }[]
};

function getListItemClassNames(type) {
  let listClass = styles['menu__list-item'];

  if (type && type === 'category') {
    listClass += ` ${styles['menu__list-item-category']}`;
  }

  return listClass.trim();
}

const externalLinkClasses = `
  ${styles['menu__list-item-link']} 
  ${styles['menu__list-item-link-external']} 
`.trim();

const Menu = ({ menu }: Props) => (
  <nav className={styles['menu']}>
    <ul className={styles['menu__list']}>
      {menu.map((item) => (
        <li className={getListItemClassNames(item.type)} key={item.path}>
          {
            // item.path.includes('http') ? (
            item.type && item.type.includes('external-link') ? (
              <a href={item.path} className={externalLinkClasses}>{item.label}</a>
            ) : (
              <Link
                to={item.path}
                className={styles['menu__list-item-link']}
                activeClassName={styles['menu__list-item-link--active']}
              >
                {item.label}
              </Link>
            )
          }

        </li>
      ))}
    </ul>
  </nav>
);

export default Menu;

// // @flow strict
// import React from 'react';
// import { Link } from 'gatsby';
// import styles from './Menu.module.scss';

// type Props = {
//   menu: {
//     label: string,
//     path: string
//   }[]
// };

// const Menu = ({ menu }: Props) => (
//   <nav className={styles['menu']}>
//     <ul className={styles['menu__list']}>
//       {menu.map((item) => (
//         <li className={styles['menu__list-item']} key={item.path}>
//           <Link
//             to={item.path}
//             className={styles['menu__list-item-link']}
//             activeClassName={styles['menu__list-item-link--active']}
//           >
//             {item.label}
//           </Link>
//         </li>
//       ))}
//     </ul>
//   </nav>
// );

// export default Menu;
